@import '../../styles/config-styles.scss';

.trailworkitem-card {
  font-family: $primary-font;
  color: $darkblue;

  .trailitem-card-image {
    max-width: 200px;
    object-fit: contain;
  }

  table {
    color: $darkblue;

    th {
      font-weight: bold;
    }
  }
}
