@import "../styles/config-styles.scss";

.navbar {
  // position: fixed !important;
  top: 0;
  width: 100%;
  height: auto;
  background-color: $darkblue;
  font-family: "Sanchez";
  color: $lighttan;
  z-index: 99;

  @media only screen and (max-width: 705px) {
    justify-content: center;
    flex-direction: column;
    .navbar-brand {
      display: none;
    }
  }

  .title-header {
    font-size: 2em;
  }

  button {
    background-color: inherit;
    border: none;
    color: $lighttan;
  }

  a {
    text-decoration: none;
    color: $lighttan;
  }

  a:hover {
    text-decoration: none;
    color: $lighttan;
    font-weight: bold;
  }

  .nct-logo {
    max-height: 50px;
  }

  .login-button:focus,
  .logout-button:focus {
    border: none;
    outline: none;
  }
}
