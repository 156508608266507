@import '../../styles/config-styles.scss';

.additembutton {
  font-size: 1em;
  color: $darkblue !important;
  padding: 0;
}

.nav-add-item {
  color: $lighttan !important;
}

.additembutton:active,
.additembutton:focus {
  outline: none;
  border: none;
}
