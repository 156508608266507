@import "../styles/config-styles.scss";
// body {
//   height: 100%;
//   overflow: hidden;
// }
.homepage {
  width: 100%;
  height: calc(100vh - 131px);
  // overflow: scroll;
  .trail-link-div {
    .trail-link {
      position: absolute;
      text-decoration: none;
      color: $lighttan;
      z-index: 10;
      background-color: $darkblue;
    }
    .link-one {
      top: 11em;
    }
    .link-two {
      top: 15em;
    }
  }
  .homepage-image {
    width: 100%;
    height: 100vh;
    // height: calc(100vh - 131px);
    object-fit: cover;
    object-position: 50% 45%;
    z-index: 5;
    @media screen and (max-width: 1025px) {
      width: 100%;
      object-fit: cover;
      object-position: 17% 50%;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      object-fit: cover;
      object-position: 17% 50%;
    }
  }
}
