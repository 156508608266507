@import "../../styles/config-styles.scss";

.volunteer-form-container {
  margin-top: $header-height;
  overflow: scroll;
}

.input-card {
  background-color: $lightblue !important;
  z-index: 5;
  .preview-card {
    position: absolute;
    background-color: $lightblue;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  h1 {
    font-size: 3vw;
    font-weight: bold;
  }
}

button[type="submit"] {
  background-color: $darkblue;
  border: $lighttan solid 2px;
}

button.preview-button {
  background-color: $darkblue;
  border: $lighttan solid 2px;
}

table {
  width: 80%;
  margin: 0 auto;
}

.error {
  color: red;
  font-weight: bold;
}

@media only screen and (max-width: 650px) {
  .volunteer-form-container {
    margin-top: $header-height-with-nav;
    h1 {
      font-size: 2em;
      font-weight: bold;
    }
  }
}
