@import "../../styles/config-styles.scss";

.input-card {
  background-color: $lightblue !important;
  z-index: 0;
  .preview-card {
    position: absolute;
    background-color: $lightblue;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  h1 {
    font-size: 3vw;
    font-weight: bold;
  }
}

button[type="submit"] {
  background-color: $darkblue;
  border: $lighttan solid 2px;
}

button.preview-button {
  background-color: $darkblue;
  border: $lighttan solid 2px;
}

table {
  width: 80%;
  margin: 0 auto;
}

.error {
  color: red;
  font-weight: bold;
}

@media only screen and (max-width: 705px) {
  .input-form-container {
    margin-top: 30px;
    .input-card {
      margin-top: 2em;
      h1 {
        font-size: 1.8em;
        font-weight: bold;
      }
    }
  }
}
