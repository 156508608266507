@import '../../styles/config-styles.scss';

.card-text input.textarea-box {
  width: 100%;
  height: 40px;
}

h5.card-title {
  font-size: 25px;
}

.editableitem-card .card {
  font-family: $primary-font;
  color: $darkblue;

  @media only screen and (max-width: 705px) {
    padding-top: 80px;
  }

  .trailitem-card-image {
    max-width: 200px;
    object-fit: contain;
  }

  .card-body > table {
    color: $darkblue;
    background-color: white;
  }
}
