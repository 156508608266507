@font-face {
  font-family: 'Sanchez';
  src: (url('../assets/fonts/Sanchez/Sanchez-Regular.ttf'));
}

$primary-font: 'Sanchez';

$darkblue: #2c6281;
$lightblue: #5ba8d9;
$darktan: #c5a34e;
$lighttan: #f7f0de;

$general-bg: #fefefe;
$header-bg: #444444;

$header-height: 76px;
$header-height-with-nav: 131px;

// :root {
//   background-color: $lighttan;
//   font-family: $primary-font, sans-serif;
// }
// body {
//   background-color: $lighttan;
// }

// .main-app {
//   height: 100vh;
// }

// .main-layout {
//   background-color: $lighttan;
//   overflow: hidden;
//   // min-height: 100vh;
//   @media only screen and (max-width: 705px) {
//     overflow: scroll;
//     min-height: 100%;
//   }
// }

// .content {
//   background-color: $lighttan;
//   overflow: hidden;
//   min-height: 100vh;

//   @media only screen and (max-width: 705px) {
//     overflow: scroll;
//     min-height: 100%;
//   }
// }

// .btn.active:focus {
//   outline: none;
//   border: none;
// }
