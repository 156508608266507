@import "../../styles/config-styles.scss";

h1.adduser-title {
  font-weight: 700;
  color: $darkblue;
  font-size: 2em;
}

@media only screen and (max-width: 705px) {
  .adduser-title {
    font-size: 1.8em;
  }
}
