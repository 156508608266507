@import '../styles/config-styles.scss';

.home-screen {
  width: 100%;
  height: calc(100vh - 76px);
  margin-top: $header-height;
  img {
    width: 100%;
    height: calc(100vh - 76px);
    object-fit: cover;
    object-position: 50% 7%;
    @media screen and (max-width: 1025px) {
      object-position: 17% 50%;
    }
  }
}
